import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as RightArrow } from "../../../assets/Icons/rightArrow.svg";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MagazineCard from "../../../Components/MagazineCard/MagazineCard";
import ShortsCard from "../../../Screens/Shorts/ShortsCard";
import Translate from "../../../Components/Multilanguage/Translate";
import { translateText } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const Categories = ({ data }) => {
  const { t } = useTranslation();
  const languageModal = useSelector((state) => state?.languageModal?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;


  const navigate = useNavigate();
  const thumbnailOrientation = data?.thumbnail_orientation ? data?.thumbnail_orientation : projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  return (
    <div className="categoriesContainer">
      <div className="wrapper">
        <div
          className="categoryNameContainer"
          onClick={() =>
            navigate(
              data?.type === 'SHORTS' ? `/shorts` : data?.type === 'FASTCHANNELS' ? `/live-channels` : `/category/${data?.key}`,
              {
                state: {
                  careers: data?.key,
                  type: data?.type,
                  thumbnailOrientation
                },
              }
            )
          }
        >
          <h1>{data?.category_name}</h1>
          {template == 'TEMPLATE_9' ? <span> {translateText(languageModal, t, 'show_all')}</span> : <RightArrow />}
        </div>
        <div className="itemsContainer">
          <Swiper
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={6}
            navigation={true}
            watchSlidesProgress
            loop={false}
            className={thumbnailOrientation === "PORTRAIT" ? "portrait" : "landscape"}
            breakpoints={{
              320: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 2 : data?.type == 'FASTCHANNELS' ? 3 : 1,
                spaceBetween: 5,
              },
              480: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 3 : data?.type == 'FASTCHANNELS' ? 3 : 2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 4 : data?.type == 'FASTCHANNELS' ? 4 : 2,
                spaceBetween: 10,
              },
              980: data?.type === "SHORTS" ? {
                slidesPerView: 4,
                spaceBetween: 10,
              } :
                {
                  slidesPerView: data?.type == 'FASTCHANNELS' ? 9 : projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : (thumbnailOrientation === "PORTRAIT" ? 7 : 4),
                  spaceBetween: 10,
                },
              1200: data?.type === "SHORTS" ? {
                slidesPerView: 5,
                spaceBetween: 10,
              } :
                {
                  slidesPerView: data?.type == 'FASTCHANNELS' ? 9 : projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : (thumbnailOrientation === "PORTRAIT" ? 7 : 4),
                  spaceBetween: 20,
                },
            }}
          >
            {data?.shows?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className={data?.type === "SHORTS" ? "shortsCardContainer" : "show"} >

                  {
                    data?.type === "MAGAZINES" ? (
                      <MagazineCard
                        data={item}
                        url={data.url}
                        metaData={true}
                        cardHover={true}
                      />
                    ) : data?.type === "SHORTS" ? (
                      <ShortsCard
                        data={item}
                        season={false}
                        metaData={true}
                        type={data?.type}
                        vanityUrl={item?.vanity_url}
                      />
                    ) : (
                      <ShowCard
                        data={item}
                        season={false}
                        metaData={true}
                        type={data?.type}
                        thumbnailOrientation={thumbnailOrientation}
                      />
                    )
                  }

                </div>

              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Categories;

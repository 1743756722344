import React, { useEffect, useState,useRef } from "react";
import initializeFirebase from '../../../firebaseConfig';
import { ref, onValue, set, push, off } from "firebase/database";
import { useSelector } from "react-redux";
import { getCurrentUTCTimestamp, timeDifferenceWithCurrenttime } from "../../../utils/utils";
import CommentReply from "./ReplyField";
import CommentItem from "./CommentItem";
import { ToastContainer,toast } from "react-toastify";
import SortIcon from '@mui/icons-material/Sort';
import ImageIcon from '@mui/icons-material/Image';
import GifIcon from '@mui/icons-material/Gif';
import SendIcon from '@mui/icons-material/Send';
import GiphySearchbox from 'react-giphy-searchbox';
import Translate from "../../../Components/Multilanguage/Translate";

import CloseIcon from '@mui/icons-material/Close';
import { commentGifUploadThumbnail, commentImageUploadThumbnail } from "../service";
import Loading from "../../../Components/Loading/Loading";
// import CloseIcon from '@mui/icons-material/Close';
let sortType = ""


const ShortComments = ({ videoId , projectInfo,setCommentModalOpen,setCommentCount  }) => {

  const firebaseDB = initializeFirebase(projectInfo);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const user = useSelector((state) => state?.user?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [comments, setComments] = useState([]);
  const [commentInputValue, setCommentInputValue] = useState();
  const [selectedSort, setSelectedSort] = useState("default");
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGif, setSelectedGif] = useState(null);
  const [image, setImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isGiphyModalOpen, setIsGiphyModalOpen] = useState(false); 
  const buttonColor = projectInfo?.projectConfig?.config?.BUTTON_COLOR || '#000';
  const gifApiKey = projectInfo?.projectConfig?.config?.GIPHY_API_KEY
  const fileInputRef = useRef(null); 
  const appInfo = {
    projectDetails: projectInfo,
    accessToken:accessToken
  };
  const onGifSelect = async (item) => {
    let imageData =[]
   
    setImage(null); 
    setImagePreview(null);
    setIsGiphyModalOpen(false);
    const gifUrl =item?.images?.fixed_width_small?.url;
   
      const response = await commentGifUploadThumbnail(gifUrl, appInfo);
    
      if(response.data.data){
        setImageDimensions({
          height:parseInt(item?.images?.fixed_width_small?.height),
          width:parseInt(item?.images?.fixed_width_small?.width),
          url:response.data.data
        }  )
        // setImageDimensions(imageData)
        setSelectedGif(item);
      }
     
  };

  const handleFileChange = async (e) => {
    // let imageData ={}
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(e?.target?.files[0]?.name)) {
      // alert(<Translate textKey={'invalid_extension_message'}/>);
    } else {
      const files = e?.target?.files;
      setSelectedGif(null); // Ensure only one of image or GIF is selected
      const response = await commentImageUploadThumbnail(files[0],appInfo)
      if(response.data.data){
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new window.Image();
          img.onload = () => {
            setImageDimensions({ width: parseInt(img.width), height: parseInt(img.height)
           , url:response.data.data
            })
          };
          img.src = reader.result;
          setImagePreview(reader.result); // Set preview URL
        };
    
        reader.readAsDataURL(files[0]);
        setImage(files[0]);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
    }
         
    }
            
  };
  const postCommentHandler = async () => {
    
    if (commentInputValue || selectedGif || image) {

      let comment_data = {
        comment_text:  commentInputValue || '',
        user_id: Number(user),
        created_at: getCurrentUTCTimestamp(),
   
      }
      if(imageDimensions != null){
        comment_data.image = imageDimensions
      }
  
      
      push(ref(firebaseDB, `comments/${videoId}`),comment_data).then(() => {
        setCommentInputValue('');
        setSelectedGif(null);
        setImage(null);
        setImagePreview(null);
     
      });
     
      
    } else {
      toast.error('Either a comment or an image/GIF is required!', {
        position: 'bottom-center',
      });
    }
  };
  const dropdownRef = useRef(null);
const handleSortClick = () => {
  setIsSortOpen(!isSortOpen);
};

const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setIsSortOpen(false);
  }
};

  const openCommentModal = () => {
    setCommentModalOpen(true);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setCommentCount(comments?.length)
  }, [comments]);
  useEffect(() => {
    const firebaseDB = initializeFirebase(projectInfo);
    
    const dbRef = ref(firebaseDB, `comments/${videoId}`);
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      const commentArray = [];
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          let keyName = childSnapshot.key;
          let data = childSnapshot.val();
         
          commentArray.push({ ...data, id: keyName, videoId: videoId });
        });
        
        if (sortType === "oldest") {
          setComments(commentArray?.filter(item => item?.delete_status !== true));

        } else {
          setComments(commentArray?.reverse()?.filter(item => item?.delete_status !== true));

        }

        setIsLoading(false)
      } else {
        setComments(null)
        setIsLoading(false)
      }
    });

    return () => off(dbRef)
  }, [videoId , projectInfo]);

  // const postCommentHandler = () => {
  //   if (commentInputValue) {
      
  //     push(ref(firebaseDB, `comments/${videoId}`), {
  //       comment_text: commentInputValue,
  //       user_id: Number(user),
  //       created_at: getCurrentUTCTimestamp(),
  //     }).then(() => {
  //       setCommentInputValue("")
  //     });
  //   } else {
  //     toast.error("Comment Field is required!", {
  //       position: "top-center"
  //     })
  //   }
  // };

  const commentsSortHandler = (type) => {
    if (type === "default" || type === "newest") {
      if (selectedSort === "oldest") {
        setComments(comments?.reverse()?.filter(item => item?.delete_status !== true))
      } else {
        setComments(comments?.filter(item => item?.delete_status !== true))
      }
    } else if (type === "oldest") {
      setComments(comments?.reverse()?.filter(item => item?.delete_status !== true))

    }
  }
  const handleGiphyButtonClick = () => {
    console.log('GIF button clicked');
    setIsGiphyModalOpen(true);
  };
  const handleRemoveSelection = () => {
    setSelectedGif(null);
    setImage(null);
    setImagePreview(null);
    setImageDimensions([])
  };
  const sortHandleChange = (value) => {
    setIsSortOpen(false);
    setSelectedSort(value)
    commentsSortHandler(value)
    sortType = value
    
  }
 
 const closeCommentModal = () => {
  setCommentModalOpen(false);
};
  return (
    <>  <div  className="comment-modal-content">
       <ToastContainer/>
    <div className="comment-modal-header">
           <h2><Translate textKey={'comments'}/> ({comments ? comments?.length : "0"})</h2>
           <div className="header-actions">
         <div className="sort-button" ref={dropdownRef}>
         <button onClick={handleSortClick}>
     <SortIcon fontSize="small" style={{ marginLeft: '5px' }} />
   </button>
           {isSortOpen && (
             <div className="sort-dropdown">
               <div onClick={(e) => sortHandleChange("newest")} ><Translate textKey={'newest'}/></div>
               <div   onClick={(e) => sortHandleChange("oldest")}><Translate textKey={'oldest'}/></div>
             </div>
           )}
         </div>
         <button className="close-modal" onClick={closeCommentModal}>
           ×
         </button>
       </div>
    
         </div>
       
         <div className="comment-modal-list">
         <div className="wrapper">
         {
          isLoading ?  
          
             <div className="loder-div">   <span class="loader"></span></div>
          : 
          <div className="bottom">
        <div className="comments">
          
          {comments?.map((item, index) => (
            <CommentItem data={item} projectInfo={projectInfo}/>
          ))}
        </div>
      </div>
      }
                        </div>
         
         </div>
   
         <div className="comment-modal-footer">
     <div className="comment-input-wrapper">
     {
             userDetails?.user_image ? (
               <img src={userDetails?.user_image} alt="Profile" className="user-avatar" />
             ) : (
               <span className="nameAvatar">{userDetails?.first_name?.slice(0, 1)?.toUpperCase()}</span>
   
             )
           }
       <div className="comment-input-container">
       <textarea
           name="comment"
           id="comment"
           cols="30"
           rows="10"
           value={commentInputValue}
           onChange={(e) => setCommentInputValue(e.target.value)}
           placeholder="Add your comment"
         ></textarea>
         <div className="upload-featue"> 
   <div className="selectedImage">
   {selectedGif && (
      
     <>
       <button className="removeButton" onClick={handleRemoveSelection}>
      <CloseIcon />
    </button>
    <img src={selectedGif.images.fixed_height.url} alt="Selected Gif" />
     </>
     
   
   
   
   
   
   )}
   {imagePreview && (
   
   
   <>
   
   <button className="removeButton" onClick={handleRemoveSelection}>
      <CloseIcon />
    </button>
    <img src={imagePreview} alt="Selected Image" />
   </>
   
   
   
   
   )}
    </div>
    <div className="comment-actions">
        
        <label htmlFor="file" className="customFileUpload">
        <ImageIcon fontSize="small" />
       
          </label>
          <input
            type="file"
            id="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
         
     
   
        <button className="action-button" onClick={handleGiphyButtonClick}>
          <GifIcon fontSize="medium" />
        </button>
        <button className="submit-button" onClick={postCommentHandler}>
        <SendIcon fontSize="medium" /> 
      </button>
      </div>
   
   
   </div>
      
   
         
       </div>
     </div>
 
   </div>
   
    
       </div>
    {isGiphyModalOpen && (
<div className="customModal">
 <div className="modalContent">
   <button className="closeButton" onClick={() => setIsGiphyModalOpen(false)}>
     &times;
   </button>
   <GiphySearchbox
     apiKey={gifApiKey}
     onSelect={onGifSelect}
     searchPlaceholder="Search"
     masonryConfig={[
       { columns: 2, imageWidth: 110, gutter: 5 },
       { mq: '700px', columns: 3, imageWidth: 120, gutter: 5 }
     ]}
   />
 </div>
</div>
)}
    </>
  
  );
};

export default ShortComments;

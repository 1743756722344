import React from "react";
import { authenticate2, logoutAll } from "../../network/service";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import Translate from "../Multilanguage/Translate";
const LogoutAllModal = ({
  showId,
  appInfo,
  setLogoutModal,
  modalType,
  videoDetails,
  subscriptionCheckAndVideoPlay
}) => {
  const user = useSelector((state)=>state?.user?.value)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const tokenAuthenticate = async () => {
    try {
      const response = await authenticate2(appInfo);
      if (response?.status === 200) {
        dispatch(
          getAccessToken({
            accessToken: response?.data?.token,
          })
        );
      }
    } catch (err) {
    }
  };

  const logoutAllDevices = async () => {
    try {
      const logoutResponse = await logoutAll(appInfo);

      if (logoutResponse?.status === 200) {
        dispatch(
          getUser({
            user: null,
          })
        );
        localStorage.removeItem("userId");
        tokenAuthenticate();
        toast.success("Logged out successfully", {
          position: "bottom-center",
        });
        setTimeout(() => {
          navigate("/login", { state: { showId, path: location?.pathname } });
        }, 1000);
      }
    } catch (err) {
    }
  };
  const logoutHandler = () => {
    logoutAllDevices();
  };

  return (
    <div className="logoutAll">
      <ToastContainer />

      <div className="overlayModal"></div>
      {
        modalType?.type === "logoutAll" | modalType?.type === "videoWatchLimitExpired" &&
      <div className="closeIconContainer" onClick={() => setLogoutModal(false)}>
        <CloseIcon />
      </div>
      }
      <div className="contents">
        {modalType?.type === "redirect" ? (
          <>
            <h1 className="error"><Translate textKey={'session_expired_message'}/></h1>
            <p><Translate textKey={'redirecting_to_login_message'}/></p>
          </>
        ) : modalType?.type === "videoWatchLimitExpired"?(
          <>
            <h1 className="error"><Translate textKey={'free_limit_expired_message'}/></h1>
            {
              !user&&
              <p><Translate textKey={'login_subscribe_text'}/></p>
            }
            <div className="buttonContainer" onClick={() => subscriptionCheckAndVideoPlay(videoDetails)}>
          <div className="background"></div>
          <button><Translate textKey={'ok'}/></button>
        </div>
          </>
        ) : (
          <>
            <h1>
              {
                modalType?.heading
              }
          
        </h1>
        <div className="buttonContainer" onClick={() => logoutHandler()}>
          <div className="background"></div>
          <button><Translate textKey={'ok'}/></button>
        </div>
          </>
        )}
        
      </div>
    </div>
  );
};

export default LogoutAllModal;

import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { appInfo, fetchEventDetails } from "../../network/service";
import { updateWatchlistData } from "../../network/service";
import { ToastContainer, toast } from "react-toastify";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { ReactComponent as PremiumIcon } from "../../assets/Icons/premium.svg";
import { imageUrlCheck } from "../../utils/utils";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import { getSelectedChannel } from "../../Redux/SelectedChannel/selectedChannelSlice";
import { getSelectedSchedule } from "../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import Translate from "../Multilanguage/Translate";

const ShowCard = ({ data, metaData, imageUrl, type, thumbnailOrientation }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [imagURL, setImageURL] = useState(thumbnailOrientation === "PORTRAIT" ? require("../../assets/Images/loading-portrait.gif") : require("../../assets/Images/loading-landscape.gif"))
  const [isImageLoad, setIsImageLoad] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    if (data?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
  }, [data]);

  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        fetchWatchlist();
        if (flag === 1) {
          // toast.success("Added to mylist", {
          //   position: "bottom-center",
          // });
          setWatchlistStatus("added");
        } else {
          // toast.success("Removed from mylist", {
          //   position: "bottom-center",
          // });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
    }
  };

  const addToWatchlist = (showId) => {
    if (user) {
      updateWatchlist(showId, 1);
    } else {
      navigate("/login");
    }
  };

  const removeFromWatchlist = (showId) => {
    updateWatchlist(showId, 0);
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub?.some((user) =>
      videoSub?.some((video) => user.sub_id === video.subscription_id)
    );
    return hasMatch;
  };

  const showHandleClick = () => {
    if (data?.type === "LIVE_EVENT") {
      navigate(`/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`)
    } else if (data?.type === "PODCAST") {
      let podcastData = {
        isOpen: true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal: podcastData
        })
      )
    } else if (data?.type === "NEWS") {
      navigate("/news")
    } else if (data?.type === "CONTINUE_WATCHING" || type === "CONTINUE_WATCHING") {
      navigate(`/videos/${data?.video_vanity_url}`, {
        state: { videoDetails: data, continue: true },
      })
    }
    else if (data?.type === "UPCOMING_EVENT") {
      navigate(`/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`)

    }
    else if (data?.type === "VIDEO") {
      navigate(`/videos/${data?.vanity_url}`, {
        state: { videoDetails: data },
      })
    }
    else if (data?.type === "SHOW") {
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { showId: data?.show_id, type: data?.type, thumbnailOrientation },
      })
    }
    else if (data?.type === "FASTCHANNEL" || data?.type === "FASTCHANNELS" || type === "FASTCHANNELS") {
      if (projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true") {
        dispatch(
          getSelectedChannel({
            selectedChannel: { channel_id: data?.channel_id }
          })
        )
        dispatch(
          getSelectedSchedule({
            selectedSchedule: { channel_id: data?.channel_id }
          })
        )
        navigate("/live-channels");
      } else {
        navigate("/player")
      }
    }
    else if (data?.type === "EVENT") {
      navigate(`/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`)
    }
    else if (type === "Episode") {
      navigate(`/videos/${data?.vanity_url}`, {
        state: { videoDetails: data },
      })
    }
    else if (type === "TOP_TRENDING") {
      navigate(`/videos/${data?.vanity_url}`, {
        state: { videoDetails: data },
      })
    }
    else if (type !== 'episodes') {
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { showId: data?.show_id, type: data?.type, thumbnailOrientation },
      })
    }

  }

  const replaceImage = (error) => {
    error.target.src = thumbnailOrientation === 'PORTRAIT' ? projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE_PORTRAIT : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }

  useEffect(() => {
    setIsImageLoad(true)
    let image;
    if (
      imageUrlCheck(
        thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200
      )
    ) {
      image =
        thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200;
    } else {
      image =
        process.env.REACT_APP_IMAGE_URL +
        (thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200);
    }

    setImageURL(image);
  }, [data, thumbnailOrientation]);
  return (
    <div className={"showCardContainer showContents"} title={data?.show_name || data?.video_title}>
      {/* <ToastContainer /> */}
      <div className="premiumStatus">
        {data?.is_free_video === true ? (
          <div className="free">
            <img
              src={require("../../assets/Images/free_icon.png")}
              alt="FreeIcon"
            />
          </div>
        )
          : data?.is_locked === true ? (
            <div className="lock">
              <img
                src={require("../../assets/Images/lock.png")}
                alt="LockIcon"
              />
            </div>
          )
            : null
        }
      </div>

      <div className="imageContainer">
        {/* {imageUrlCheck(thumbnailOrientation==='PORTRAIT'?(data?.logo || data?.thumbnail):(data?.logo_thumb || data?.thumbnail_350_200)) ===false ? (
          <img
            src={thumbnailOrientation==='PORTRAIT'?`${process.env.REACT_APP_IMAGE_URL}${
              (data?.logo || data?.thumbnail)
            }`:`${process.env.REACT_APP_IMAGE_URL}${
              (data?.logo_thumb || data?.thumbnail_350_200)
            }`}
            alt="ShowImage"
          />
        ) : (
          <img src={thumbnailOrientation==='PORTRAIT'?
          `${data?.logo || data?.thumbnail}`
          :
          `${data?.logo_thumb || data?.thumbnail_350_200}`
        } alt="ShowImage" />
          
        )} */}
        <img
          className={!isImageLoad ? (thumbnailOrientation === "PORTRAIT" ? "portrait" : "landscape") : "showImage"}
          src={
            imagURL
          }
          key={data?.show_id}
          onError={(e) => replaceImage(e)}
          alt="ShowImage"
        />
        {
          data?.type === "CONTINUE_WATCHING" &&
          <div className="continueWatchingBar">
            <div className="line" style={{ width: data?.watched_percentage <= 1 ? 1 + "%" : data?.watched_percentage + "%" }}></div>
          </div>
        }

        <div className="extraActions">
          <div
            className="showCardOverlay"
            onClick={showHandleClick}
          ></div>
          <div
            className="playIconContainer"
            onClick={showHandleClick}
          >
            <PlayIcon />
          </div>
          {metaData && data?.show_id && (
            <>
              {watchlistStatus === "added" ? (
                <div
                  className="addToList"
                  onClick={() => removeFromWatchlist(data?.show_id)}
                >
                  <span><Translate textKey={'remove_from_mylist'} /></span>
                </div>
              ) : (
                <div
                  className="addToList"
                  onClick={() => addToWatchlist(data?.show_id)}
                >
                  <span><Translate textKey={'add_to_mylist'} /></span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="metaData">
        {data?.type != 'FASTCHANNEL' && <h1 className="title">{data?.show_name || data?.video_title}</h1>}
        {metaData ? (
          <>
            <div className="datas">
              <div className="left">
                {
                  data?.year &&
                  <span className="year">{data?.year}</span>
                }
                {
                  data?.duration_text &&
                  <span className={`duration ${data?.year && 'showDot'}`}>{data?.duration_text}</span>
                }
              </div>
              <div className="right">
                {
                  data?.rating &&
                  <span className="certificate">{data?.rating}</span>
                }
              </div>
            </div>
            <div className="genres">
              {/* {
            data?.category_names?.map((item,index)=>(
              <span key={index}>{item}</span>
            ))
          } */}
            </div>
          </>
        ) : (
          null
        )}
      </div>
    </div>
  );
};

export default ShowCard;

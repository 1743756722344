import React from "react";
import Translate from "../../Components/Multilanguage/Translate";
const LandingScreeen = () => {
  return (
    <div className="landingScreen">
      <div className="spotlight rightType">
        <div className="left">
          <h1 className="heading"><Translate textKey={'welcome_template_message'}/></h1>
          <p className="description">
          <Translate textKey={'watch_movies_shows_message'}/>
          </p>
          <div className="buttonContainer">
            <div className="background"></div>
            <button><Translate textKey={'sign_to_template'}/></button>
          </div>
        </div>
        <div className="right">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>
      </div>

      <div className="watchNowSection leftType">
        <div className="left">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>

        <div className="right">
                <h1 className="heading"><Translate textKey={'welcome_template_message'}/></h1>
                <p className="description"><Translate textKey={'watch_movies_shows_message'}/></p>
                <div className="buttonContainer">
                    <div className="background"></div>
                    <button><Translate textKey={'watch_now'}/></button>
                </div>
            </div>
      </div>

      <div className="other rightType">
        <div className="left">
          <h1 className="heading"><Translate textKey={'welcome_template_message'}/></h1>
          <p className="description">
          <Translate textKey={'watch_movies_shows_message'}/>
          </p>
          <div className="buttonContainer">
            <div className="background"></div>
            <button><Translate textKey={'sign_to_template'}/></button>
          </div>
        </div>
        <div className="right">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>
      </div>

      <div className="device">
        <div className="left">
            <h1 className="heading"><Translate textKey={'favorite_channel_message'}/></h1>
            <p className="description"><Translate textKey={'favorite_channel_description_message'}/></p>
        </div>
        <div className="right">
            <ul className="devices">
                <li><span>Roku</span></li>
                <li><span>Apple TV</span></li>
                <li><span>Android TV</span></li>
                <li><span>Android</span></li>
                <li><span>IOS</span></li>
                <li><span>Amazon Fire</span></li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default LandingScreeen;

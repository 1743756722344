import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { checkOperatingSystem } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import Translate from "../Multilanguage/Translate";
const OpenApp = ({setIsOpenAppFeature,setIsOpenFeatureClosed}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [ done,setDone] = useState()
  const navigate = useNavigate()
  const notNowHandler = () => {
    setIsOpenAppFeature(false)
    setIsOpenFeatureClosed(true)
  }
  // window.addEventListener('visibilitychange', function() {
  //   clearTimeout(openAppTimeout);
  // });
  // useEffect(()=>{
  //   if(done){
  //     setTimeout(() => {
  //       window.location.replace(projectInfo?.projectConfig?.config?.APPLINK_URL)
  
  
  //     }, 1000);

  //   }

  // },[done])

  const openAppHandler = () => {
    window.location.href = projectInfo?.projectConfig?.config?.APPLINK_URL
  }
  return (
    <div className='openAppContainer'>
        <h1><Translate textKey={'switch_to_app_title'}/></h1>
        <p><Translate textKey={'switch_to_app_sub_text'}/></p>
        <div className="buttons">
            <button onClick={notNowHandler} className="notNow"><Translate textKey={'switch_to_app_deny'}/></button>
            <button onClick={openAppHandler} className="openApp"><Translate textKey={'switch_to_app_confirm'}/></button>
        </div>
    </div>
  )
}

export default OpenApp
import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Translate from "../Multilanguage/Translate";
const MagazineCard = ({ data,url, metaData, imageUrl, type,cardHover,modalPageNavigation,showCount,categoryData }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  
  const [isHovered, setIsHovered] = useState(false);
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [imageURL,setImageURL] = useState(projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  



  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (isCardHovered) {
      setIsCardHovered(false);
    }
  };
  const handleCardHover = () => {
    setIsCardHovered(true);
  };
  return (
    <div
      className={cardHover ? `magazineCardContainer showContents ` : `magazineCardContainer`}
      title={data?.show_name}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave} >
      <div className="showMoreOverlay" onClick={()=>window.open(url,'_blank')}>
        <h2><Translate textKey={'show_all'} />( {showCount} )</h2>
      </div>
      <div className={`square ${isCardHovered ? 'cardHovered' : ''}`} onClick={() => window.open(data?.url, '_blank')} onMouseEnter={handleCardHover}>
        <div className="imageContainer">  
          <img
            src={imageURL}
            key={data?.show_id}
            onError={(e) => replaceImage(e)}
            onLoad={(e) => setImageURL(data?.logo)}
            alt="ShowImage"
          />
        </div> 
        <div className="metaData">
         <h1 className="title">{data?.show_name}</h1>
         {/* <h1 className="synopsis">{data?.synopsis}</h1> */}
         <div className="buttons">
            <button onClick={()=>window.open(data?.url,'_blank')}>  
              <Translate textKey={'download'} />
            </button>
            </div>
        </div>
      </div>
     
    </div>
    
  );
};

export default MagazineCard ;

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading/Loading";
import ShowCard from "../ShowCard/ShowCard";
import * as service from "./service";
import { useWindowWidth } from "@react-hook/window-size";
import { rowItemCount } from "../../utils/utils";
import useScrollPosition from "../ScrollPosition/useScrollPosition";
import MagazineCard from "../MagazineCard/MagazineCard";
import Translate from "../Multilanguage/Translate";
const CommonPage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);


  const [shows, setShows] = useState([]);
  const [img, setImg] = useState(true);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [rowItemsClass,setRowItemsClass] = useState("")
  const [sliceCount,setSliceCount] = useState(20)

  const {careers} = useParams();
  const type = location?.state?.type;
  const thumbnailOrientation = (location?.state?.thumbnailOrientation) ? (location?.state?.thumbnailOrientation):( projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION);
  const navigate = useNavigate();
  const windowSize = useWindowWidth()
  const containerRef = useRef(null)
  const scrollPosition = useScrollPosition();
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;  
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    // if (menuItem) {
    fetchCategoryDetails();
    // } else {
    //   fetchCategoryDetails();
    // }
  }, [location]);

  useEffect(()=>{
    if(!loading && sliceCount<shows?.shows?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,shows])

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount4")
      }
    }else{
      setRowItemsClass("")
    }
  }, [projectInfo,windowSize]);

  const  fetchCategoryDetails= async () => {
    setLoading(true)
    try {
      const itemResponse = await service.getAllShowsOfCategory(appInfo, careers);
      if (itemResponse?.status === 200) {
        setShows(itemResponse?.data?.data);
        setImg(false);
        setLoading(false)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
      setLoading(false)

    }
  };
  // const fetchCategoryDetails = async () => {
  //   setLoading(true)
  //   try {
  //     const categoryResponse = await service.getAllShowsOfCategory(
  //       appInfo,
  //       location?.state
  //     );
  //     if (categoryResponse?.status === 200) {
  //       setShows(categoryResponse?.data?.data);
  //       setImg(true);
  //       setLoading(false)
  //     }
  //   } catch (err) {
  //     setLoading(false)
  //     toast.error(err?.response?.data?.message, {
  //       position: "top-center",
  //     });
  //   }
  // };

  const lazyLoad = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    if(scrollPosition + 2200 >= containerHeight){
      setSliceCount(sliceCount+20)
    }
  }
  if(loading){
    return(
      <Loading/>
    )
  }

  

  return (
    <>
   
      <div className="commonPage" ref={containerRef}>
      <ToastContainer />

      {shows?.shows?.length  ===0 | Object.entries(shows).length === 0 ? (
        <h1
          style={{
            color: "#E72A31",
            display:"flex",
            justifyContent:'center',
          }}
        >
          <Translate textKey={'no_data_found'} />
        </h1>
      ) : (
        <>
          {shows?.banner ? (
            <div
              className={template === "TEMPLATE_9" ? 'bannerContainer' : 'bgImageContainer'}
              style={{
                backgroundImage: template === "TEMPLATE_9"
                  ? `linear-gradient(90deg, #000 0%, transparent), url(${shows?.banner})`
                  : `url(${shows?.banner})`,
               
              }}
            >
            
                <div className="topContainer">
                <div className={template === "TEMPLATE_9" ? "topbanner" : "top"}>
                  <h1 className="heading">{shows?.category_name}</h1>
                  <p className="desc">{shows?.synopsis}</p>
                </div>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}${shows?.banner}`}
                  alt="BgImage"
                />
                <div
                  className="bottomGradient"
                  style={{ background: template === "TEMPLATE_9" ? "none" : "" }}
                />
                </div>
                
                


                
              </div>
            ) : (
              <div className="top">
                <h1 className="heading">{shows?.category_name}</h1>
                <p className="desc">{shows?.synopsis}</p>
              </div>
            )}

          <div className="wrapper">
            <div className="bottom">
              <div className="itemsContainer">  
                {shows?.category_name !=="Magazines" ?(
                  shows?.shows?.slice(0,sliceCount)?.map((item, index) => (
                    <div
                      className={thumbnailOrientation===`PORTRAIT`?`items portrait ${rowItemsClass}`:`items landscape ${rowItemsClass}`}
                    >
                      <ShowCard
                        data={item}
                        key={index}
                        imageUrl={img}
                        season={false}
                        metaData={true}
                        type={type}
                        thumbnailOrientation ={thumbnailOrientation}
                      />
                    </div>
                  ))
                ) : (
                  shows?.shows?.slice(0,sliceCount)?.map((item, index) => (
                    <div
                      className={
                        shows?.category_name==="Magazines"
                          ? `items portrait ${rowItemsClass}`
                          : `items landscape ${rowItemsClass}`
                      }
                    >
                      <MagazineCard
                        data={item}
                        metaData={true}
                        cardHover={true}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>

        </>

  );
};

export default CommonPage;

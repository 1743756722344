import React, { useEffect, useState } from "react";
import { ReactComponent as StarIcon } from "../../assets/Icons/star.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { updateUserRating } from "../../network/service";
import { useSelector } from "react-redux";
import Translate from "../Multilanguage/Translate";

const RatingsAndReviewModal = (props) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [hoveredStar, setHoveredStar] = useState();
  const [selectedStar, setSelectedStar] = useState();
  const [reviewValue,setReviewValue] = useState("")
  const [remainingWords,setRemainingWords] = useState(420)
  const [buttonDisabled,setButtonDisabled] = useState(false)
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(()=>{
    if(reviewValue){
      setRemainingWords(420-reviewValue.length)
    }
    if(reviewValue.length===0){
      setRemainingWords(420)

    }
  },[reviewValue])

  useEffect(()=>{
    if(selectedStar){
      setButtonDisabled(false)
    }else{
      setButtonDisabled(true)
    }
  },[selectedStar])
  const submitButton = () => {
    if(selectedStar){
      const values = {
        showId:props?.showId,
        ratedCount:selectedStar,
        review:reviewValue
      }
      postUserRating(values)
    }
  };

  const handleChange = (e) => {
    setReviewValue(e.target.value)
  }

  const postUserRating =  async (values) => {
    const response = await updateUserRating(appInfo,values)
    if(response?.status === 200){
      props?.setRatingReviewModal(false)
      props?.setUserRatedValue(selectedStar)
    }
  }
  return (
    <div className="ratingsAndReview">
      <div
        className="overlayModal"
        onClick={() => props.setPasswordModal(false)}
      ></div>
      <div className="container">
        <div className="closeIconContainer" onClick={()=>props?.setRatingReviewModal(false)}>
          <CloseIcon />
        </div>
        <h1 className="heading">{props?.showName}</h1>
        <p><span>{props?.director}</span>{props?.year}</p>
        <div className="ratings" 
            onMouseLeave={()=>setHoveredStar(null)}
        
        >
          {/* {
                [1,2,3,4,5].map((item,index)=>(
                    <div className={"star"} key={index} onMouseEnter={()=>setHoveredStar(index+1)}>
                        <StarIcon/>
                    </div>

                ))
            } */}
          <div
            className={selectedStar>=1?"active star":hoveredStar>=1?"active star":"no-active star"}
            onMouseEnter={() => setHoveredStar(1)}
            onMouseLeave={()=>setHoveredStar(null)}
            onClick={()=>setSelectedStar(1)}
          >
            <StarIcon />
          </div>

           <div
            className={selectedStar>=2?"active star":hoveredStar>=2?"active star":"no-active star"}
            onMouseEnter={() => setHoveredStar(2)}
            onMouseLeave={()=>setHoveredStar(1)}
            onClick={()=>setSelectedStar(2)}

          >
            <StarIcon />
          </div>

           <div
            className={selectedStar>=3?"active star":hoveredStar>=3?"active star":"no-active star"}
            onMouseEnter={() => setHoveredStar(3)}
            onMouseLeave={()=>setHoveredStar(2)}
            onClick={()=>setSelectedStar(3)}

          >
            <StarIcon />
          </div>

           <div
            className={selectedStar>=4?"active star":hoveredStar>=4?"active star":"no-active star"}
            onMouseEnter={() => setHoveredStar(4)}
            onMouseLeave={()=>setHoveredStar(3)}
            onClick={()=>setSelectedStar(4)}


          >
            <StarIcon />
          </div>

           <div
            className={selectedStar>=5?"active star noMargin":hoveredStar>=5?"active star noMargin":"no-active star noMargin"}
            onMouseEnter={() => setHoveredStar(5)}
            onMouseLeave={()=>setHoveredStar(4)}
            onClick={()=>setSelectedStar(5)}


          >
            <StarIcon />
          </div>
          {
            reviewValue && !selectedStar&&
          <div className="ratingValidation">
            <p><Translate textKey={'rate_and_review'}/></p>
          </div>
          }

        </div>
        <div className="review">
          <textarea
            placeholder="Add a review?"
            name="review"
            id="review"
            cols="30"
            value={reviewValue}
            onChange={(e)=>handleChange(e)}
            maxLength={420}
          ></textarea>
        </div>
        <div className="bottom">
          <div className="left">
            <span className="remainingWords">{remainingWords} <Translate textKey={'characters_remain'}/></span>
          </div>
          <div className="right">
            <div className="buttonContainer">
              <button onClick={submitButton} disabled={buttonDisabled}><Translate textKey={'submit'}/></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingsAndReviewModal;

import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as Closeicon } from "../../assets/Icons/closeFilled.svg";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "react-toastify/dist/ReactToastify.css";
import { authenticate2, getPlayerToken, getUserSubscription, updateWatchlistData } from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import ShowsRow from "../../Components/ShowsRow/ShowsRow";
import { toast, ToastContainer } from "react-toastify";
import WatchWithoutAdsModal from "../../Components/Modals/WatchWithoutAdsModal";
import LogoutAllModal from "../../Components/Modals/LogoutAllModal";
import { convertAdUrl, imageUrlCheck, onVideoPlayFunction } from "../../utils/utils";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import Loading from "../../Components/Loading/Loading";
import ContinueWatchingModel from "../../Components/Modals/ContinueWatchingModel";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import PlayNextEpisode from "../../Components/Modals/PlayNextEpisode";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import parse from "html-react-parser";
import { ReactComponent as Warning } from "../../assets/Icons/warning.svg";
import { updateB2BViewCOunt } from "./service";
import Playlist from "../PlaylistPage/Playlist";
import axios from "axios";
import { getDownloadProgress } from "../../Redux/DownloadB2bSlice/DownloadB2bSlice";
import TrailerModal from "../../Components/Modals/TrailerModal";
import ProgressBar from "../../Components/Modals/LoginModal/ProgressBarModal";
import Translate from "../../Components/Multilanguage/Translate";
let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;
const DetailsScreen = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const downloadB2bModal = useSelector((state) => state?.downloadB2bModal?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const [isShareActive, setIsShareActive] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [isShowDownload, setIsShowDownload] = useState(false);
  const [isShowDownloadRight, setIsShowDownloadRight] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [isTrailer, setIsTrailer] = useState(false)
  const [isContinueWatchingModal, setIsContinueWatchingModal] = useState(false);
  const [continueWathchingVideoDetails, setContinueWatchingVideoDetails] = useState();
  const [watchDuration, setWatchDuration] = useState("");
  const [pageURL, setPageURL] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [similarVideos, setSimilarVideos] = useState([]);
  const [playerToken, setPlayerToken] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState("");
  const [watchListStatus, setWatchListStatus] = useState()
  const [episodeDetails, setEpisodeDetails] = useState({})
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [watchWithoutAds, setWatchWithoutAds] = useState(false);
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [categories, setCategories] = useState("");
  const [trailerPlaying, setTrailerPlaying] = useState(true);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [adUrl, setAdUrl] = useState("");
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(false);
  const [subscribedUser, setSubscribeduser] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const [pipMode, setPipMode] = useState(false);
  const [nowPlaying, setNowPlaying] = useState("");
  const [episodes, setEpisodes] = useState();
  const [nextEpisodeModal, setNextEpisodeModal] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [isFreeVideo, setIsFreeVideo] = useState(false); // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [directSubscribeState, setDirectSubscribeState] = useState({
    flag: false,
    data: null,
  });

  const [subscribeButton, setSubscribeButton] = useState();
  const [subtitleInfo, setSubTitleInfo] = useState();
  const [countinueWatchingVideoPlayedStatus, setCountinueWatchingVideoPlayedStatus] = useState(false);
  const [vPlayer, setVPlayer] = useState()
  const position = useScrollPosition();
  const location = useLocation();
  const showId = location?.state?.showId;
  const type = location?.state?.type;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [videoType, setVideoType] = useState("video")
  const [invalidURL, setInvalidURL] = useState(false);
  const [b2bMessage, setB2BMessage] = useState("");
  const [playlistData, setPlaylistData] = useState({});
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [trailerUrl, setTrailerUrl] = useState("");
  let shareRef = useRef(null);
  let reportRef = useRef(null);
  let shareIconRef = useRef(null);
  let shareTwoRef = useRef(null);
  let reportTwoRef = useRef(null);
  let shareIconTwoRef = useRef(null);
  let WarningIconRef = useRef(null);
  let episodeCount = 0;
  let isLive = false

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };
  const playerRef = useRef(null);

  useEffect(() => {
    if (projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT") {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    setAdUrl(null);
    if (showDetails) {
      if (Object.entries(showDetails).length > 0) {
        if (showDetails?.type === "linear_event") {
          setVideoType("event")
        } else {
          setVideoType("video")
        }
        if (showDetails?.categories) {
          let categoryNames;
          showDetails?.categories?.map((item) => {
            categoryNames = categoryNames ? categoryNames + item?.category_name + "," : item?.category_name + ",";
          });
          setCategories(categoryNames);
        }
        if (showDetails?.watchlist_flag === 1) {
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
        if (type === "CONTINUE_WATCHING" && countinueWatchingVideoPlayedStatus === false) {
          if (showDetails?.single_video === 0) {
            if (showDetails?.videos && showDetails?.videos[0]?.videos) {
              setEpisodes(showDetails?.videos[0]?.videos);

              let filteredVideo = showDetails?.videos.map((item) => {
                return item?.videos?.filter((videoItem) => videoItem?.video_id === location?.state?.videoId);
              });
              setSelectedVideoDetails(filteredVideo[0][0]);
              // subscriptionCheckAndVideoPlay(filteredVideo[0][0]);
            } else {
              setEpisodes(showDetails?.videos);

              let filteredVideo = showDetails?.videos?.filter((item) => item?.video_id === location?.state?.videoId);
              setSelectedVideoDetails(filteredVideo[0]);
              // subscriptionCheckAndVideoPlay(filteredVideo[0]);
            }
          } else if (showDetails?.single_video === 1) {
            setEpisodes(null);
            if (showDetails?.videos?.length > 0) {
              setSelectedVideoDetails(showDetails?.videos[0]);
              // subscriptionCheckAndVideoPlay(showDetails?.videos[0]);
            }
          }
        } else {
          if (showDetails?.teaser_flag === 1) {
            let trailer = true;
            if (showDetails?.teaser) {
              let arr = showDetails?.teaser?.split("/").reverse();
              fetchPlayerToken(trailer, arr[1]);
              setNowPlaying("Trailer");
            }

          }
          if (showDetails?.single_video === 1) {
            setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
            setEpisodes(null);
          } else {
            if (showDetails?.videos && showDetails?.videos[0]?.videos && showDetails?.videos[0]?.videos[0]) {
              setSelectedVideoDetails(showDetails?.videos[0]?.videos[0]);
              setEpisodes(showDetails?.videos[0]?.videos);
            } else {
              setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
              setEpisodes(showDetails?.videos);
            }
          }
        }
      }
    }
  }, [showDetails, type]);

  useEffect(() => {
    if (position === 0) {
      setPipMode(true);
    }
  }, [position]);

  useEffect(() => {
    window.scroll(0, 0);
    // if (accessToken && Object.keys(projectInfo).length > 0) {

    if (location?.pathname) {
      let vanityUrl = location?.pathname.split("/")[2];
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        fetchShowDetailsByB2bURL(vanityUrl);
      } else {
        fetchShowDetails();
      }

      if (showId) {
        fetchSimilarVideos();
      }
    }
    // }
  }, [accessToken, projectInfo, location?.pathname]);


  useEffect(() => {
    if (selectedVideoDetails) {
      if (Object.entries(selectedVideoDetails).length > 0) {
        if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
          setInitialVideoSubscriptions(selectedVideoDetails?.subscriptions);
          if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
            if (selectedVideoDetails?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
              let isSubscribedUser;
              if (showDetails?.subscriptions?.length > 0) {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                    : false;
              } else {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, selectedVideoDetails?.subscriptions)
                    : false;
              }

              if (isSubscribedUser === true) {
                setSubscribeduser(true);
                setSubscribeButton(false);
              } else {
                setSubscribeduser(false);

                if (selectedVideoDetails?.free_video === true) {
                  // for inital free video checking for showing button name "subscribe" ,"play Now"
                  setSubscribeButton(false);

                  setIsFreeVideo(true);
                } else {
                  setSubscribeButton(true);

                  setIsFreeVideo(false);
                }
              }
            } else {
              setSubscribeduser(true);
              setSubscribeButton(false);
            }
          } else {
            setSubscribeduser(true);
            setSubscribeButton(false);
          }
        } else if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "false") {
          setSubscribeButton(false);
          if (userSubscriptionData?.login_needed === false) {
            setIsFreeVideo(true);
          } else {
            setIsFreeVideo(false);
          }
          setInitialVideoSubscriptions(selectedVideoDetails?.subscriptions);
        }

        if (showDetails) {
          var updateDuration = selectedVideoDetails?.watched_duration;
          setWatchDuration(updateDuration);
        }
        // playVideo(selectedVideoDetails)
        const fetchVideoDetails = async () => {
          try {
            const response = await service.getVideoDetails(selectedVideoDetails.video_id, appInfo);
            if (response?.status === 200) {
              // const videoSubResponse = await fetchVideoSubscription(response?.data?.data?.video_id)
              if (response?.status === 200) {
                const videoData = response?.data?.data;
                const videoName = videoData?.video_name;
                const updatedSelectedVideoDetails = {
                  ...selectedVideoDetails,
                  video_name: videoName,
                };

                // Update the state with the new object
                setSelectedVideoDetails(updatedSelectedVideoDetails);
                // setVideoDetails(response?.data?.data);
                //   setSubscriptions(videoSubResponse?.data?.data)
                setLoading(false)
                if (response?.data?.data?.watchlist_flag === 1)
                  setWatchListStatus("added")
              } else {
                setWatchListStatus("removed")
              }
            }
          } catch (err) {
            setLoading(false)
          }
        }

      }
    }
  }, [selectedVideoDetails]);

  useEffect(() => {
    setVideoPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: videoPlay,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],

        // poster:require("../../../images/thumb.png")
      };
      setLoading(false);

      setSkeleton(false);
      setVideoPlayer(
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
        />
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [videoUrl]);

  useEffect(() => {
    setPageURL(window.location.href);
    fetchUserSubscriptionDetails();
  }, []);
  useEffect(() => {
    setVPlayer(null);

    if (videoUrl) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
        // poster:require("../../../images/thumb.png")
      };

      setVPlayer(
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
        />
      );
    }
  }, [videoUrl]);
  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !shareIconRef?.current?.contains(e.target) &&
        !shareRef?.current?.contains(e.target) &&
        !shareIconTwoRef?.current?.contains(e.target) &&
        !shareTwoRef?.current?.contains(e.target)
      ) {
        setIsShareActive(false);
      }
      if (!reportRef?.current?.contains(e.target) && !reportTwoRef?.current?.contains(e.target)) {
        // setIsShowDownload(false);
      }
    };
    window.addEventListener("click", handleOutClick);
  }, [shareRef, reportRef, shareTwoRef, reportTwoRef]);
  useEffect(() => {
    setVideoPlayer(null);
    if (trailerUrl) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${trailerUrl}`,
            type: "application/x-mpegURL",
          },
        ],
      };

      setVideoPlayer(
        <VideoJS
          options={videoJsOptions}
        />
      );
      setLoading(false)
      updateViewCount(showDetails.b2b_id)
    }
  }, [trailerUrl])

  const fetchPlayerTokenTrailer = async (trailerDetails) => {
    let arr = trailerDetails.teaser?.split("/").reverse();
    const playerTokenResponse = await getPlayerToken(appInfo, arr[1]);
    if (playerTokenResponse?.status === 200) {
      setTrailerUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
        arr[1] +
        "&token=" +
        playerTokenResponse?.data?.data +
        "&type=trailer&pubid=" + appInfo?.projectDetails?.projectConfig?.pubid
      );
      return playerTokenResponse?.data?.data;
    }
  };
  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  const fetchShowDetails = async () => {
    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(appInfo, showId);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        if (showDetailsResponse?.data?.data?.teaser_flag !== 1) {
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchShowDetailsByVanityUrl = async (vanityUrl) => {
    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(appInfo, vanityUrl);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        if (showDetailsResponse?.data?.data?.teaser_flag !== 1) {
          setLoading(false);
        }
        if (!showId) {
          fetchSimilarVideos(showDetailsResponse?.data?.data?.show_id);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchShowDetailsByB2bURL = async (b2bURL) => {
    try {
      setLoading(true);
      const b2bDetailsResponse = await service.getB2BDetails(
        appInfo,
        b2bURL
      );
      if (b2bDetailsResponse?.status === 200) {
        if (b2bDetailsResponse?.data?.data?.sign_in_required) {
          if (b2bDetailsResponse?.data?.data?.sign_in_required && b2bDetailsResponse?.data?.data?.b2b_id) {
            setShowDetails(b2bDetailsResponse?.data?.data);
            setLoading(false);
          } else {
            if (!user) {
              navigate("/login", { state: { path: location?.pathname } });
            } else {
              setInvalidURL(true);
              setB2BMessage(b2bDetailsResponse?.data?.message)
              setLoading(false);
            }
          }
        } else {
          if (b2bDetailsResponse?.data?.message === 'B2B url details') {
            setShowDetails(b2bDetailsResponse?.data?.data);
            if (b2bDetailsResponse?.data?.data?.type === "TRAILER") {
              fetchPlayerTokenTrailer(b2bDetailsResponse?.data?.data)
              setShowDownload(true)
            }
            setLoading(false);
          } else {

            setInvalidURL(true);
            setB2BMessage(b2bDetailsResponse?.data?.message)
            setLoading(false);
          }

        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchSimilarVideos = async (sid) => {
    let id = showId ? showId : sid;
    const similarVideosResponse = await service.getSimilarVideos(appInfo, id);
    if (similarVideosResponse?.status === 200) {
      setSimilarVideos(similarVideosResponse?.data?.data);
    }
  };

  const fetchPlayerToken = async (isTrailer, videoId) => {
    const playerTokenResponse = await getPlayerToken(appInfo, videoId);
    if (playerTokenResponse?.status === 200) {
      setPlayerToken(playerTokenResponse?.data?.data);
      if (isTrailer) {
        let arr = showDetails?.teaser?.split("/").reverse();
        setVideoUrl(
          "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
          arr[1] +
          "&token=" +
          playerTokenResponse?.data?.data +
          "&type=trailer" +
          "&pubid=" +
          projectInfo?.projectConfig?.pubid
        );
      }
      return playerTokenResponse?.data?.data;
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          toast.success("Added to mylist", {
            position: "bottom-center",
            toastId: "success1",
          });
          setWatchlistStatus("added");
        } else {
          toast.success("Removed from mylist", {
            position: "bottom-center",
          });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
    }
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };



  const redirectToLogin = () => {
    dispatch(
      getUser({
        user: null,
      })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate();
    navigate("/login", {
      state: { path: location?.pathname, showId: showId },
    });
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };

  const handleSubscribe = (data) => {
    if (user) {
      localStorage.setItem("selectedAmount", data?.price);
      let vanityUrl = location?.pathname.split("/")[2];
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        localStorage.setItem("vanityUrl", vanityUrl);
      } else {
        localStorage.setItem("showId", showId);
      }

      localStorage.setItem("selectedSubId", data?.subscription_id);
      navigate("/payment", {
        state: { subscription: { ...data, is_coupon: false } },
      });
    } else {
      navigate("/login", {
        state: { path: location?.pathname, showId: showId },
      });
    }
  };

  const subscriptionCheckAndVideoPlay = async (videoDetails, directSubscribe, directSubscribeData) => {
    if (user) {
      if (directSubscribe) {
        setDirectSubscribeState({ flag: true, data: directSubscribeData });
      } else {
        setDirectSubscribeState({ flag: false, data: null });
      }
      if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
        if (userSubscriptionData?.forcibleLogout === true) {
          setLogoutModal(true);
          setModalType({
            type: "logoutAll",
            heading: "You’ve reached the maximum Device limit.Do you want to logout from all devices",
          });
        } else if (userSubscriptionData?.session_expired === true) {
          setModalType({ type: "redirect" });
          setLogoutModal(true);
          setTimeout(() => {
            redirectToLogin();
          }, 1000);
        } else {
          // const videoSubscriptionData = await fetchVideoSubscription(
          //   videoDetails?.video_id
          // );
          // setVideoSubscriptions(videoSubscriptionData);
          if (videoDetails?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
            let isSubscribedUser;
            if (showDetails?.subscriptions?.length > 0) {
              isSubscribedUser =
                userSubscriptionData?.data?.length > 0
                  ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                  : false;
            } else {
              isSubscribedUser =
                userSubscriptionData?.data?.length > 0
                  ? subscriptionCheck(userSubscriptionData?.data, videoDetails?.subscriptions)
                  : false;
            }
            if (isSubscribedUser === true) {
              if (videoDetails?.watched_duration) {
                setWatchDuration(videoDetails?.watched_duration);
                setIsContinueWatchingModal(true);
              } else {
                playVideo(videoDetails);
              }
            } else {
              let vanityUrl = location?.pathname.split("/")[2];
              if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
                localStorage.setItem("vanityUrl", vanityUrl);
              } else {
                localStorage.setItem("showId", showId);
              }
              if (videoDetails?.free_video === true) {
                if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
                  // setAdUrl(convertAdUrl(selectedVideoDetails, showDetails, appInfo));

                  setWatchWithoutAds(true);
                } else {
                  if (videoDetails?.watched_duration) {
                    setWatchDuration(videoDetails?.watched_duration);
                    setIsContinueWatchingModal(true);
                  } else {
                    playVideo(videoDetails);
                  }
                }
              } else {
                if (directSubscribe === true) {
                  handleSubscribe(directSubscribeData);
                } else {
                  // navigate("/subscription", {
                  //   state: { subscriptionList: videoSubscriptionData },
                  // });
                  navigate("/subscription", {
                    state: { videoId: videoDetails?.video_id },
                  });
                }
              }
            }
          } else {
            if (showDetails) {
              if (videoDetails?.watched_duration) {
                setWatchDuration(videoDetails?.watched_duration);
                setIsContinueWatchingModal(true);
              } else {
                if (userSubscriptionData?.data?.length > 0 && videoDetails?.subscriptions?.length === 0) {
                  playVideo(videoDetails);
                } else {
                  // if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
                  //   setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo,isLive));
                  // }
                  //needed in video details page
                  playVideo(videoDetails);
                }
              }
            }
            // playVideo(videoDetails);
          }
        }
      } else {
        if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
          if (videoDetails?.watched_duration) {
            // setWatchDuration(videoDetails?.watched_duration);
            // setIsContinueWatchingModal(true);
            playVideo(videoDetails);

          } else {
            // setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo,isLive));
            playVideo(videoDetails);
          }
        } else {
          playVideo(videoDetails);
        }
      }
    } else {
      navigate("/login", {
        state: { path: location?.pathname, showId: showId },
      });
    }
  };

  const watchVideoHandler = async (videoDetails, directSubscribe, directSubscribeData) => {

    setSelectedVideoDetails({ ...videoDetails, showId: showId });
    if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
      // subscriptionCheckAndVideoPlay(videoDetails, directSubscribe, directSubscribeData);
    } else {
      if (userSubscriptionData?.login_needed === false) {
        if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
          setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
        }
        playVideo(videoDetails);
      } else if (userSubscriptionData?.login_needed === true) {
        setModalType({ type: "videoWatchLimitExpired" });
        setLogoutModal(true);
      } else {
        // subscriptionCheckAndVideoPlay(videoDetails, directSubscribe, directSubscribeData);
      }
    }
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    if (!trailerPlaying) {
      player.on("play", () => {
        let event = videoStarted === true ? "POP09" : "POP02";
        videoStarted = true;
        videoPlaying = true;
        updateVideoAnalytics(event, player.currentTime());
      });
      player.on("loadedmetadata", () => {
        player.pause();
        if (watchDuration > 0) {
          player.currentTime(watchDuration.toString());
          player.play();
          videoPlaying = true;
        } else {
          player.play();
          videoPlaying = true;
        }
      });
      player.on("timeupdate", function (e) {
        let event = "POP03";
        videoPlaying = true;
        var hasPlayedTime = player.currentTime();
        var intPlayedTime = parseInt(hasPlayedTime, 10);
        var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
        if (isSixty && debounce) {
          debounce = false;
          prevTime = intPlayedTime;
          updateVideoAnalytics(event, player.currentTime());
        } else {
          if (debounce == false && prevTime != intPlayedTime) {
            debounce = true;
          }
        }
      });

      player.on("pause", () => {
        let event = "POP04";
        videoPlaying = true;
        updateVideoAnalytics(event, player.currentTime());
      });
      player.on("ended", () => {
        let event = "POP05";
        videoPlaying = false;
        episodeCount = findIndex() + 1;

        updateVideoAnalytics(event, player.currentTime());
        if (episodes[episodeCount]) {
          setNextEpisodeModal(true);
        }
      });
      player.on("dispose", () => {
        videoPlaying = false;
        videoStarted = false;
        player.pause();
      });
    } else {
      player.on("timeupdate", () => {
        videoPlaying = true;
      });
      player.on("dispose", () => {
        videoPlaying = false;
      });
    }

  };

  const updateVideoAnalytics = async (event, time) => {
    const isLive = "0";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      selectedVideoDetails,
      event,
      time,
      isLive,
      categories,
      showDetails?.show_id
    );
  };

  const findIndex = () => {
    let elementIndex = null;
    if (episodes) {
      episodes?.filter((filteringItem, index) => {
        if (filteringItem?.video_id === selectedVideoDetails?.video_id) {
          elementIndex = index;
        }
      });
    }
    return elementIndex;
  };

  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true);
  });

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false);
  });

  if (loading) {
    return <Loading />;
  }
  const playVideo = async (selectedVideoDetails, type) => {
    try {
      const response = await service.getVideoDetails(selectedVideoDetails.video_id, appInfo, showDetails?.b2b_id);
      if (response?.status === 200) {
        setShowVideo(true);
        setShowDownload(true)
        const videoData = response?.data?.data;
        const videoName = videoData?.video_name;

        const arr = videoName?.split("/").reverse();

        const playerTokenData = await fetchPlayerToken(null, arr[1]);
        if (videoData?.video_title) {
          setNowPlaying(videoData?.video_title);
        }
        updateViewCount(showDetails?.b2b_id);
        if (type === "episode") {
          setEpisodeDetails(selectedVideoDetails)
        } else if (type === "B2bPlaylist") {
          setShowDownload(true)
          setPlaylistData(selectedVideoDetails)
        }
        if (playerTokenData) {
          setVPlayer(null);
          setVideoUrl(
            "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
            arr[1] +
            "&token=" +
            playerTokenData +
            "&type=" +
            videoType +
            "&pubid=" +
            projectInfo?.projectConfig?.pubid
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateViewCount = async (id) => {
    await updateB2BViewCOunt(
      appInfo,
      id
    );
  }

  const downloadPlaylistVideo = async (b2b_id, video_id, name) => {
    setIsShowDownload(false)
    setShowDownload(false)
    if (downloadB2bModal?.downloadProgress > 0) {
      toast('Download Already Exists!!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      setShowDownload(true)
    } else {
      const response = await service.downloadPlaylist(appInfo, b2b_id, video_id, name)
      const urlToDownload = response?.data?.data;
      const parsedUrl = new URL(urlToDownload);
      const filename = decodeURIComponent(parsedUrl.pathname.split('/').pop());
      if (response?.status === 200) {
        toast('Download Will Begin Shortly!!', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
        });
        const downloadProgress = (event) => {
          const total = event.total;
          const loaded = event.loaded;
          const progress = (loaded / total) * 100;
          dispatch(
            getDownloadProgress({
              downloadB2bModal: {
                showDownload: false,
                downloadProgress: progress.toFixed(2),
                filename: filename
              }
            })
          )
        };
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
        try {
          const response = await fetch(urlToDownload, {
            method: "GET",
          });

          if (!response.ok) {
            throw new Error("Network Error");
          }

          const reader = response.body.getReader();
          const contentLength = +response.headers.get("Content-Length");
          let receivedLength = 0;
          const chunks = [];

          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              break;
            }

            chunks.push(value);
            receivedLength += value.length;

            console.log('downloadprogress', {
              loaded: receivedLength,
              total: contentLength,
              progress: (receivedLength / contentLength) * 100,
            });


            if (downloadProgress) {
              downloadProgress({
                loaded: receivedLength,
                total: contentLength,
                progress: (receivedLength / contentLength) * 100,
              });
            }
          }

          const blob = new Blob(chunks);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          toast.success('Download completed!', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });

          dispatch(
            getDownloadProgress({
              downloadB2bModal: {
                showDownload: true,
                downloadProgress: 0
              }
            })
          )
          setShowDownload(true)
        } catch (error) {
          console.error('Download Error:', error);
        }
      } else {
        toast('Error Occured!!', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
        });
        setShowDownload(true)
      }
    }
  };

  return (
    <div className="b2bdetailsScreen">
      <ToastContainer />
      {watchWithoutAds && (
        <WatchWithoutAdsModal
          setWatchWithoutAds={setWatchWithoutAds}
          showDetails={showDetails}
          appInfo={appInfo}
          setAdUrl={setAdUrl}
          videoDetails={selectedVideoDetails}
          playVideo={playVideo}
          setIsContinueWatching={setIsContinueWatchingModal}
          showDuration={watchDuration}
          videoPlay={setVideoPlay}
          directSubscribeState={directSubscribeState}
          handleSubscribe={handleSubscribe}
          islive={isLive}

        />
      )}
      {nextEpisodeModal && (
        <PlayNextEpisode
          episodes={episodes}
          findIndex={findIndex}
          watchVideoHandler={watchVideoHandler}
          setNextEpisodeModal={setNextEpisodeModal}
        />
      )}
      {isContinueWatchingModal && (
        <ContinueWatchingModel
          setIsContinueWatchingModal={setIsContinueWatchingModal}
          videoDetails={selectedVideoDetails}
          playVideo={playVideo}
          // playContinueVideo={playContinueVideo}
          watchDuration={watchDuration}
          showDetails={showDetails}
          appInfo={appInfo}
          setAdUrl={setAdUrl}
          islive={isLive}
        />
      )}
      {logoutModal && (
        <LogoutAllModal
          showId={showId}
          appInfo={appInfo}
          setLogoutModal={setLogoutModal}
          modalType={modalType}
          videoDetails={selectedVideoDetails}
        // subscriptionCheckAndVideoPlay={subscriptionCheckAndVideoPlay}
        />
      )}

      {invalidURL ? (
        <div className="expired-b2b ">
          <div className="warningIcon" ref={WarningIconRef}>
            <Warning />
          </div>
          <div className="warningText">
            <span className="expired-text">{b2bMessage}</span>
          </div>
        </div>) : (
        <>
          {
            isTrailer && showDetails?.type &&
            <TrailerModal appInfo={appInfo} teaser={showDetails?.teaser} showName={showDetails?.show_name} setIsTrailer={setIsTrailer} />
          }
          {
            showDetails?.type === "PLAYLIST" ?
              <>
                {videoPlayer && (
                  <div
                    className={
                      pipMode === false
                        ? "videoContainerPlaylist"
                        : position > 700 && browserPipMode === false && videoPlaying === true
                          ? "videoContainerPlaylist heightIncrease"
                          : "videoContainerPlaylist"
                    }
                  >
                    <div
                      className={
                        position > 700 && browserPipMode === false && videoPlaying === true && pipMode === true
                          ? "playListplayer pipMode"
                          : "playListplayer"
                      }
                    >
                      <div className="gradientTop"></div>
                      <div className="closeIcon" onClick={() => setPipMode(false)}>
                        <Closeicon />
                      </div>
                      {
                        videoPlaying &&
                        <div className="nowPlayingPlaylilst">
                          <h1><Translate textKey={'now_playing'}/>: {nowPlaying}</h1>
                        </div>
                      }
                      {
                        showVideo ? vPlayer : null
                      }

                    </div>
                  </div>
                )}
                {
                  showDetails?.download_enabled && showDownload ?
                    <div className="downloadPlaylist" onClick={() => downloadPlaylistVideo(showDetails.b2b_id, playlistData.video_id, "videoFlag")}><button>
                      <Translate textKey={'download'}/></button>
                    </div> : null
                }

                <Playlist video={showDetails} type="B2bPlaylist" playVideo={playVideo} b2bId={showDetails.b2b_id} />

              </>
              : showDetails?.type === "TRAILER" ?
                <div className="wrapper">

                  <div className='trailer'>
                    <div className="trailerVideoPlayer">
                      {videoPlayer}
                    </div>
                    <div className="videoTitle">
                      <h1 className='title'>{showDetails?.title}</h1>

                      {

                        showDetails?.download_enabled && showDownload &&

                        <div className="trailerDownload" onClick={() => downloadPlaylistVideo(showDetails?.b2b_id, showDetails?.trailer_id, "trailerOnlyFlag")}><button><Translate textKey={'download'}/></button></div>


                      }
                    </div>

                  </div>

                </div>
                :

                <div className="wrapper">
                  <div className="bgImageContainer">
                    {imageUrlCheck(showDetails?.logo_thumb) ? (
                      <img
                        src={`${showDetails?.logo_thumb}`}
                        alt="Background"
                      />
                    ) : (
                      <img
                        src={
                          `${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo_thumb}`
                        }
                        alt="Background"
                      />
                    )}
                    <div className="gradient"></div>
                  </div>
                  {videoPlayer && (
                    <div
                      className={
                        pipMode === false
                          ? "videoContainer"
                          : position > 700 && browserPipMode === false && videoPlaying === true
                            ? "videoContainer heightIncrease"
                            : "videoContainer"
                      }
                    >
                      <div
                        className={
                          position > 700 && browserPipMode === false && videoPlaying === true && pipMode === true
                            ? "player pipMode"
                            : "player"
                        }
                      >
                        <div className="gradientTop"></div>
                        <div className="closeIcon" onClick={() => setPipMode(false)}>
                          <Closeicon />
                        </div>
                        {
                          videoPlaying &&
                          <div className="nowPlaying">
                            <h1><Translate textKey={'now_playing'}/> : {nowPlaying}</h1>
                          </div>
                        }
                        {/* // } */}
                        {
                          showVideo ? vPlayer : null
                        }

                      </div>
                    </div>
                  )}
                  <div className="details">
                    <div className="leftContainer">
                      <div className="showCardDetails">
                        <div className="imageContainer">
                          {imageUrlCheck(thumbnailOrientation === "PORTRAIT" ? showDetails?.logo : showDetails?.logo_thumb) ? (
                            <img
                              src={thumbnailOrientation === "PORTRAIT" ? `${showDetails?.logo}` : `${showDetails?.logo_thumb}`}
                              alt="Background"
                            />
                          ) : (
                            <img
                              src={
                                thumbnailOrientation === "PORTRAIT"
                                  ? `${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo}`
                                  : `${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo_thumb}`
                              }
                              alt="Background"
                            />
                          )}
                        </div>

                        <div className="watchNow" onClick={() => playVideo(selectedVideoDetails)}>
                          <button>{"Watch Now"}</button>
                        </div>
                        {
                          showDetails?.teaser_flag === 1 && showDetails?.teaser &&
                          <div className="trailerWatchNowButton" onClick={() => setIsTrailer(true)}>
                            <button><Translate textKey={'trailer'}/></button>
                          </div>
                        }
                        {

                          showDetails?.download_enabled && showDownload &&

                          <div className="addtoList" onClick={() => setIsShowDownload(true)}><button><Translate textKey={'download'}/></button></div>


                        }


                        {isShowDownload ? (
                          <div className={isShowDownload ? "reportProblemContainer active" : "reportProblemContainer"}>
                            <div className="closeIcon" onClick={() => setIsShowDownload(false)}>
                              <Closeicon />
                            </div>
                            <div className="modal-content">

                              <span onClick={() => downloadPlaylistVideo(showDetails.b2b_id, selectedVideoDetails.video_id, "videoFlag")}><Translate textKey={'video'}/></span>
                              {showDetails?.teaser_flag === 1 && showDetails?.teaser && <span onClick={() => downloadPlaylistVideo(showDetails.b2b_id, showDetails.show_id, "trailerFlag")}><Translate textKey={'trailer'}/></span>}

                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="rightContainer">
                      <div className="showMetadata">

                        {
                          Object.keys(episodeDetails).length > 0 ? (<><h1 className="name">{episodeDetails?.video_title}</h1>
                            <div className="data">
                              <span className="year">{episodeDetails?.year}</span>
                              <span className={`duration ${episodeDetails?.year && "showDot"}`}>{episodeDetails?.duration_text}</span>
                              {
                                episodeDetails?.rating &&
                                <span className="certificate">{episodeDetails?.rating}</span>
                              }
                            </div>
                            <div className="genres">
                              {episodeDetails?.categories?.map((item, index) => (
                                <span key={index}>{item?.category_name}</span>
                              ))}
                            </div></>) : <>
                            <h1 className="name">{showDetails?.show_name}</h1>
                            <div className="data">
                              <span className="year">{showDetails?.year}</span>
                              <span className={`duration ${showDetails?.year && "showDot"}`}>{showDetails?.duration_text}</span>
                              {
                                showDetails?.rating &&
                                <span className="certificate">{showDetails?.rating}</span>
                              }
                            </div>
                            <div className="genres">
                              {showDetails?.categories?.map((item, index) => (
                                <span key={index}>{item?.category_name}</span>
                              ))}
                            </div>
                          </>


                        }

                        <div className="watchNow" onClick={() => playVideo(selectedVideoDetails)}>
                          <button><Translate textKey={'watch_now'}/></button>
                        </div>
                        {
                          showDetails?.teaser_flag === 1 && showDetails?.teaser &&
                          <div className="trailerWatchNowButton" onClick={() => setIsTrailer(true)}>
                            <button><Translate textKey={'trailer'}/></button>
                          </div>
                        }

                        {

                          showDetails?.download_enabled && showDownload &&

                          <div className="addtoList" onClick={() => setIsShowDownloadRight(true)}><button><Translate textKey={'download'}/></button></div>


                        }
                        {isShowDownloadRight ? (
                          <div className={isShowDownloadRight ? "reportProblemContainer active" : "reportProblemContainer"}>
                            <div className="closeIcon" onClick={() => setIsShowDownloadRight(false)}>
                              <Closeicon />
                            </div>
                            <div className="modal-content">

                              <span onClick={() => downloadPlaylistVideo(showDetails.b2b_id, selectedVideoDetails.video_id, "videoFlag")}><Translate textKey={'video'}/></span>
                              {showDetails?.teaser_flag === 1 && showDetails?.teaser && <span onClick={() => downloadPlaylistVideo(showDetails.b2b_id, showDetails.show_id, "trailerFlag")}><Translate textKey={'trailer'}/></span>}

                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <p className="description">{(showDetails?.synopsis || showDetails?.video_description) && parse(showDetails?.synopsis?.split("\n")?.join("<br>") || showDetails?.video_description?.split("\n")?.join("<br>"))}</p>
                        <div className="info">
                          {showDetails?.show_cast && (
                            <div className="starring">
                              <div className="left">
                                <h6><Translate textKey={'starring'}/></h6>
                              </div>
                              <div className="right">
                                <ul className="names">
                                  <li>{showDetails?.show_cast}</li>
                                </ul>
                              </div>
                            </div>
                          )}
                          {showDetails?.director && (
                            <div className="director">
                              <div className="left">
                                <h6><Translate textKey={'directed_by'}/></h6>
                              </div>
                              <div className="right">
                                <h6>{showDetails?.director}</h6>
                              </div>
                            </div>
                          )}
                        </div>
                        {!subscribedUser || selectedVideoDetails?.free_video === true ? (
                          showDetails?.subscriptions?.length > 0 || selectedVideoDetails?.free_video === true ? (
                            <div className="subscriptions">
                              {showDetails?.subscriptions?.map((item, index) => (
                                <div className="items" key={index} onClick={() => handleSubscribe(item)}>
                                  {item?.subscription_text}
                                </div>
                              ))}
                              {showDetails?.single_video === 1 && selectedVideoDetails?.free_video === true && (
                                <div className="items free" onClick={() => watchVideoHandler(selectedVideoDetails)}>
                                 <Translate textKey={'free'}/>
                                </div>
                              )}
                            </div>
                          ) : null
                        ) : null}
                      </div>

                      {showDetails?.single_video !== 1 && showDetails?.videos[0]?.videos && showDetails?.videos?.length === 1 && (
                        <ShowsRow
                          season={false}
                          data={showDetails?.videos[0]?.videos}
                          title={<Translate textKey={'episodes'}/>}
                          metaData={true}
                          watchVideoHandler={watchVideoHandler}
                          type="episodes"
                          thumbnailOrientation={thumbnailOrientation}
                          b2b={true}
                          playVideo={playVideo}
                        />
                      )}
                      {showDetails?.single_video === 0 && showDetails?.videos[0]?.videos && showDetails?.videos?.length > 1 && (
                        <ShowsRow
                          season={true}
                          data={showDetails?.videos}
                          title={<Translate textKey={'episodes'}/>}
                          metaData={true}
                          watchVideoHandler={watchVideoHandler}
                          type="episodes"
                          thumbnailOrientation={thumbnailOrientation}
                          b2b={true}
                          playVideo={playVideo}
                        />
                      )}
                      {showDetails?.single_video === 0 && !showDetails?.videos[0]?.videos && (
                        <ShowsRow
                          season={false}
                          data={showDetails?.videos}
                          title={<Translate textKey={'episodes'}/>}
                          metaData={true}
                          watchVideoHandler={watchVideoHandler}
                          type="episodes"
                          thumbnailOrientation={thumbnailOrientation}
                          b2b={true}
                          playVideo={playVideo}
                          singleEpisode={true}
                        />
                      )}


                    </div>
                  </div>
                </div>
          }



        </>)}
      {/* {
        downloadB2bModal?.downloadProgress > 0 && !downloadB2bModal?.showDownload && <ProgressBar downloadB2bModal={downloadB2bModal} />
      } */}
    </div>
  );
};

export default DetailsScreen;

import React, { useEffect, useRef, useState } from 'react'
import { getEventInfo, getLiveInfo } from './service'
import Loading from '../../Components/Loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
import VideoJS from '../../Components/VideoJsPlayer/VideoJS'
import { getUserSubscription } from "../../network/service";
import { getVideoSubscription } from "../Subscription/service";
import { convertTimeToLocal, onVideoPlayFunction ,translateText} from '../../utils/utils'
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import Translate from '../../Components/Multilanguage/Translate'
import { useTranslation } from 'react-i18next';
let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;

const LivePlayer = () => {
  const { t } = useTranslation();
  const languageModal = useSelector((state) => state?.languageModal?.value);
  const [loading, setLoading] = useState(true)
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const user = useSelector((state) => state?.user?.value);
  const location = useLocation()
  const [liveDetails, setLiveDetails] = useState()
  const [livePlayer, setLivePlayer] = useState()
  const [liveStarted, setLiveStarted] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId: sessionId
  }
  const playerRef = useRef(null);
  const liveDetailsRef = useRef(liveDetails);

  useEffect(() => {
    liveDetailsRef.current = liveDetails;
  }, [liveDetails]);

  const eventId = location?.state?.eventId || localStorage.getItem("eventId")


  useEffect(() => {
    setLoading(true)

    if (eventId) {
      localStorage.removeItem("eventId")
      fetchEventInfo()
    } else {
      fetchLiveInfo()
    }
  }, [])

  useEffect(() => {
    const startTime = new Date(liveDetails?.up_next?.start_time).getTime();
    const currentTime = new Date().getTime();

    // Calculate the time difference
    const timeUntilStart = startTime - currentTime;

    if (timeUntilStart > 0) {
      const timer = setTimeout(() => {
        // console.log('Up next has started!');

        if (eventId) {
          fetchEventInfo()
        } else {
          fetchLiveInfo()
        }
      }, timeUntilStart);

      return () => clearTimeout(timer);
    }

    if (timeUntilStart <= 0) {
      // console.log('Up next has already started!');
    }

  }, [liveDetails]);

  useEffect(() => {

    if (!liveStarted && liveDetails) {
      setLiveStarted(true);
      const hasLiveUrl = liveDetails && liveDetails.live_url;

      if (hasLiveUrl) {
        checkSubscriptionDetails()
      } else {
        toast.error(<Translate textKey={'wrong_try_again_msg'}/>)
      }

    }
  }, [liveDetails])

  const subscriptionCheck = async (userSubscription, channelSubscription) => {
    return userSubscription?.some((user) => channelSubscription?.some((channel) => user?.sub_id === channel?.subscription_id));
  };

  const checkSubscriptionDetails = async () => {

    const channelId = appInfo?.projectDetails?.projectConfig?.config?.FAST_CHANNEL_ID;
    const channelSubscriptionResponse = await getVideoSubscription(appInfo, channelId, 'channel');

    if (channelSubscriptionResponse?.status == 200) {

      if (channelSubscriptionResponse?.data?.data.length > 0) {

        if (!user) {

          if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
            navigate("/checkout", { state: { channelId } });
          } else {
            navigate("/login", { state: { ...location.state, path: location?.pathname, channelId } });
          }
        } else {
          const userSubscriptionResponse = await getUserSubscription(appInfo);

          if (userSubscriptionResponse?.status == 200) {

            if (userSubscriptionResponse?.data?.data.length > 0) {

              const subscriptionStatus = await subscriptionCheck(userSubscriptionResponse?.data?.data, channelSubscriptionResponse?.data?.data);

              if (subscriptionStatus === true) {

                startLivePlayer()
              } else {
                redirectToSubscription(channelId)
              }
            } else {
              redirectToSubscription(channelId)
            }

          } else {
            toast.error(<Translate textKey={'wrong_try_again_msg'}/>);
          }

        }
      } else {
        startLivePlayer()
      }

    } else {
      toast.error(<Translate textKey={'wrong_try_again_msg'}/>);
    }

  };

  const redirectToSubscription = (channelId) => {
    if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
      localStorage.setItem("channelId", channelId);
      localStorage.setItem("vanityUrl", '/live');
      navigate("/checkout", { state: { channelId } });
    } else {
      navigate("/subscription", { state: { channelId } });
    }
  }

  const startLivePlayer = async () => {
    const videoJsOptions = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      controlBar: {
        pictureInPictureToggle: false,
      },
      sources: [
        {
          src: liveDetails.live_url,
          type: "application/x-mpegURL",
        },
      ],
      // poster:require("../../../images/thumb.png")
    };
    setLivePlayer(<VideoJS onReady={handlePlayerReady} options={videoJsOptions} liveData={liveDetails} is_live={true} />)
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });

    player.on("timeupdate", function (e) {
      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateLiveAnalytics(
          eventId ? liveDetails : liveDetails?.now_playing,
          event,
          player.currentTime()
        );
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });
    player.on("ended", () => {
      let event = "POP05";
      updateLiveAnalytics(
        eventId ? liveDetails : liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });


    player.on("dispose", () => {
      videoStarted = false;
      // player.pause();
    });
  };

  const updateLiveAnalytics = async (nowPlaying, event, time) => {
    const isLive = "1";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      liveDetailsRef?.current,
      event,
      time,
      isLive
    );
  };

  const fetchLiveInfo = async () => {
    try {
      const response = await getLiveInfo(appInfo);
      setLiveDetails(response?.data?.data[0])
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const fetchEventInfo = async () => {
    try {
      const response = await getEventInfo(appInfo, eventId);
      setLiveDetails(response?.data?.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <div className="livePlayer">
      <div className="wrapper">
        <div className="videoContainer">
          {livePlayer}
          {
            !eventId &&
            <div className="bottom">
              <div className="details">
                <div className="left">
                  <h1 className="heading">{translateText(languageModal, t, 'now_playing')}</h1>
                  <h1 className="title">
                    {liveDetails?.now_playing?.video_title}
                  </h1>
                  <span className="time">
                    {convertTimeToLocal(liveDetails?.now_playing?.start_time)
                      .toString()
                      .toLowerCase()}
                  </span>
                  <p className="description">
                    {liveDetails?.now_playing?.video_description}
                  </p>
                </div>
                <div className="right">
                  <h1 className="heading">{translateText(languageModal, t, 'up_next')}</h1>
                  <h1 className="title">{liveDetails?.up_next?.video_title}</h1>
                  <span className="time">
                    {convertTimeToLocal(liveDetails?.up_next?.start_time)
                      // .toString()
                      // .toLowerCase()
                    }

                  </span>

                  <p className="description">
                    {liveDetails?.up_next?.video_description}
                  </p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default LivePlayer
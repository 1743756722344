import React, { useRef } from "react";
import { useState } from "react";
import { Country, State } from "country-state-city";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { updateContactSuppport } from "./service";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2';
import Translate from "../../Components/Multilanguage/Translate";
import { translateText } from "../../utils/utils";
import { useTranslateText } from "../../Components/Multilanguage/Translate";
const ContactSupport = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);

  const [values, setValues] = useState({});
  const [states, setstates] = useState();
  const [country, setCountry] = useState();
  const [disableblock, setDisableblock] = useState(false);
  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null);
  const [processState,setProcessState] = useState(false);
  const captchaRef = useRef(null);
   const translateText = useTranslateText();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const location = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const advertise = urlParams.get("adv");
  // const advertise = location?.state?.advertise;

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);  
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let country = Country.getAllCountries();
    setCountry(country);
  }, []);

  useEffect(() => {
    advertise && setValues({
      ...values,
      ["issues"]: "Advertise with us",
    });
  }, []);

  const formValidation = () => {
    const { country, email, issues, name, state, message, platform, topic } =
      values;
    let validationStatus = true;
    let error = {};
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
    if (projectInfo?.projectConfig?.config?.GOOGLE_CAPTCHA_KEY && !captchaValue) {      
      error.captcha = <Translate textKey={'required_captcha'}/>;
      validationStatus = false;
    }

    if (!country || country === "Select Country") {
      error.country = <Translate textKey={'required'}/>;
      validationStatus = false;
    }

    if (!email) {
      error.email = <Translate textKey={'required'}/>;
      validationStatus = false;
    } else if (!email?.match(emailRegex)) {
      error.email = <Translate textKey={'valid_email_message'}/>;
      validationStatus = false;
    }

    if (!issues) {
      error.issues = <Translate textKey={'required'}/>;
      validationStatus = false;
    } else if (issues === "Technical Issue") {
      if (!platform || platform === "Select Platform") {
        error.platform = <Translate textKey={'required'}/>;
        validationStatus = false;
      }
      if (!topic || topic === "Select Topic") {
        error.topic = <Translate textKey={'required'}/>;
        validationStatus = false;
      }
    }

    if (!name) {
      error.name = <Translate textKey={'required'}/>;
      validationStatus = false;
    }

    if (!state || state === "Select State") {
      error.state = <Translate textKey={'required'}/>;
      validationStatus = false;
    }

    if (!message) {
      error.message = <Translate textKey={'required'}/>;
      validationStatus = false;
    } else if (message?.length < 5) {
      error.message = <Translate textKey={'minimum_character_required'}/>;
      validationStatus = false;
    }
    setErrors(error)
    return validationStatus;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      let state = State.getStatesOfCountry(value);
      setstates(state);
      setValues({
        ...values,
        [name]: value,
      });
    } else if (name === "issues") {
      setValues({
        ...values,
        [name]: value,
      });
      if (value === "Technical Issue") {
        setDisableblock(true);
      } else {
        setDisableblock(false);
      }
    } else {

      setValues({
        ...values,
        [name]: value,
      });
    }
  };
  const onDialCodeChangeHandler = (phone_number) => {
    setValues({
      ...values,
      phone: phone_number,
    });
  }
  
  const submitHandler = (e) => {
    setProcessState(true);
    e.preventDefault()
    if (formValidation()) {
      let country = Country.getAllCountries();
      const countryArray = country.filter(
        (countryItem) => countryItem.isoCode == values?.country
      );
      values.country = countryArray[0]?.name;
      updateContact();
    }
  }

  const updateContact = async () => {
    try {
      const response = await updateContactSuppport(appInfo, values);
      if (response?.status === 200) {
        if(projectInfo?.projectConfig?.config?.GOOGLE_CAPTCHA_KEY){
          captchaRef.current.reset(); 
          setCaptchaValue(null)
        }
        setProcessState(false);
        toast.success("Thanks. We will get back to you shortly", {
          position: "bottom-center",
        });

      }
    } catch (err) {
      toast.error(err?.message, {
        position: "bottom-center",
      });
    }

  }

  return (
    <div className="contactSupport">
      <ToastContainer />
      <div className="wrapper">
        <h1 className="heading"><Translate textKey={'support'}/></h1>
        <div className="formContainer">
          <form>
            <div className="full">
              <input
                type="text"
                name="name"
                placeholder={translateText("name")}
                onChange={handleChange}
              />
              {
                errors?.name &&
                <span className="error">{errors?.name}</span>
              }
            </div>
            <div className="full">
              <input
                type="email"
                name="email"
                placeholder={translateText("email")}
                onChange={handleChange}
              />
              {
                errors?.email &&
                <span className="error">{errors?.email}</span>
              }
            </div>

            <div className="full">
              <PhoneInput
                enableSearch={true}
                dropdownStyle={{
                  height: '200px',
                  textAlign: 'justify',
                }}
                country={'us'}
                inputStyle={{
                  width: '100%',
                  padding: '0% 7%', 
                  height: '60px',
                }}
                containerClass="phone-input-responsive" 
                type="tel"
                placeholder={translateText("phone_optional")}
                name="phone"
                onChange={onDialCodeChangeHandler}
              />
            </div>

            <div className="full">
              <select name="country" id="country" onChange={handleChange}>
                <option><Translate textKey={'select_country'}/></option>
                {country?.map((item, index) => {
                  return (
                    <option key={index} value={item.isoCode}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {
                errors?.country &&
                <span className="error">{errors?.country}</span>
              }
            </div>
            <div className="full">
              <select name="state" id="state" onChange={handleChange}>
                <option><Translate textKey={'select_state'}/></option>
                {states?.map((item, index) => {
                  return (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {
                errors?.state &&
                <span className="error">{errors?.state}</span>
              }
            </div>
            <div className="full">
              <input
                type="text"
                name="movie"
                placeholder={translateText("contact_movie_placeholder")}
                onChange={handleChange}
              />
              {
                errors?.movie &&
                <span className="error">{errors?.movie}</span>
              }
            </div>

            <div className="full">
              {advertise ? (
                <input type="text" value={translateText("advertise_with_us")} disabled />
              ) : (
                <select name="issues" id="issues" onChange={handleChange}>
                  <option><Translate textKey={'contact_select_one_placeholder'}/></option>
                  <option value="Technical Issue"><Translate textKey={'technical_issue'}/></option>
                  <option value="Advertise with us" ><Translate textKey={'advertise_with_us'}/></option>
                  <option value="Partner with us"><Translate textKey={'partner_with_us'}/></option>
                  <option value="Other"><Translate textKey={'other'}/></option>
                </select>
              )}

              {errors?.issues && <span className="error">{errors?.issues}</span>}
            </div>

            {disableblock === true && (
              <>
                <div className="full">
                  <select name="platform" id="platform" onChange={handleChange}>
                    <option><Translate textKey={'select_platform'}/></option>
                    <option value="Website">Website</option>
                    <option value="Roku">Roku</option>
                    <option value="Android">Android</option>
                    <option value="iOS">iOS</option>
                    <option value="Apple TV">Apple TV</option>
                    <option value="Android TV">Android TV</option>
                    <option value="Amazon Fire">Amazon Fire</option>
                  </select>
                  {
                    errors?.platform &&
                    <span className="error">{errors?.platform}</span>
                  }
                </div>
                <div className="full">
                  <select name="topic" id="topic" onChange={handleChange}>
                    <option value="Select Topic"><Translate textKey={'select_topic'}/></option>
                    <option value="Account"><Translate textKey={'account'}/></option>
                    <option value="Audio"><Translate textKey={'audio'}/></option>
                    <option value="Content"><Translate textKey={'content'}/></option>
                    <option value="Content Request"><Translate textKey={'content_request'}/></option>
                    <option value="Registration"><Translate textKey={'registration'}/></option>
                    <option value="Sign-In"><Translate textKey={'sign_in'}/></option>
                    <option value="Subtitle"><Translate textKey={'subtitle_caption'}/></option>
                    <option value="Video Playlist"><Translate textKey={'video_playlist'}/></option>
                    <option value="Other"><Translate textKey={'other'}/></option>
                  </select>
                  {
                    errors?.topic &&
                    <span className="error">{errors?.topic}</span>
                  }
                </div>
              </>
            )}

            <div className="full message">
              <label htmlFor="message"><Translate textKey={'message'}/></label>
              <textarea name="message" onChange={handleChange}></textarea>
              {
                errors?.message &&
                <span className="error">{errors?.message}</span>
              }
            </div>
            {projectInfo?.projectConfig?.config?.GOOGLE_CAPTCHA_KEY && <div className="full">
              <div className="buttonContainer captcha-container">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={projectInfo?.projectConfig?.config?.GOOGLE_CAPTCHA_KEY}
                  onChange={handleCaptchaChange}
                  theme="dark"
                />
                {
                  errors?.captcha &&
                  <span className="error">{errors?.captcha}</span>
                }
              </div>
            </div>}
            <div className="full">
              <div className="buttonContainer" onClick={(e) => submitHandler(e)}>
                <div className="background" />
                <button
                  disabled={processState}
                  style={{
                    opacity: processState ? 0.5 : 1,
                    cursor: processState ? 'not-allowed' : 'pointer'
                  }}
                >
                  {processState ? <><Translate textKey={'processing'}/> ...</> : <Translate textKey={'submit'}/>}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSupport;
